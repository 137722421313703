
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pro-voltus-primary: mat-palette($mat-indigo);
$pro-voltus-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pro-voltus-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pro-voltus-theme: mat-light-theme((
  color: (
    primary: $pro-voltus-primary,
    accent: $pro-voltus-accent,
    warn: $pro-voltus-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($pro-voltus-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '~swiper/swiper-bundle';
@font-face {
  font-family: 'Sofia Pro Medium';
  src: url('assets/fonts/SofiaPro-Medium.eot');
  src: url('assets/fonts/SofiaPro-Medium.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-Medium.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-Medium.woff') format('woff'),
      url('assets/fonts/SofiaPro-Medium.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-Medium.svg#SofiaPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro Bold';
  src: url('assets/fonts/SofiaPro-Bold.eot');
  src: url('assets/fonts/SofiaPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-Bold.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-Bold.woff') format('woff'),
      url('assets/fonts/SofiaPro-Bold.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-Bold.svg#SofiaPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro Light';
  src: url('assets/fonts/SofiaPro-Light.eot');
  src: url('assets/fonts/SofiaPro-Light.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-Light.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-Light.woff') format('woff'),
      url('assets/fonts/SofiaPro-Light.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-Light.svg#SofiaPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('assets/fonts/SofiaPro.eot');
  src: url('assets/fonts/SofiaPro.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro.woff2') format('woff2'),
      url('assets/fonts/SofiaPro.woff') format('woff'),
      url('assets/fonts/SofiaPro.ttf') format('truetype'),
      url('assets/fonts/SofiaPro.svg#SofiaPro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro SemiBold';
  src: url('assets/fonts/SofiaPro-SemiBold.eot');
  src: url('assets/fonts/SofiaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-SemiBold.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-SemiBold.woff') format('woff'),
      url('assets/fonts/SofiaPro-SemiBold.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-SemiBold.svg#SofiaPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro Extralight';
  src: url('assets/fonts/SofiaPro-ExtraLight.eot');
  src: url('assets/fonts/SofiaPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-ExtraLight.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-ExtraLight.woff') format('woff'),
      url('assets/fonts/SofiaPro-ExtraLight.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-ExtraLight.svg#SofiaPro-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro Black';
  src: url('assets/fonts/SofiaPro-Black.eot');
  src: url('assets/fonts/SofiaPro-Black.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-Black.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-Black.woff') format('woff'),
      url('assets/fonts/SofiaPro-Black.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-Black.svg#SofiaPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro UltraLight';
  src: url('assets/fonts/SofiaPro-UltraLight.eot');
  src: url('assets/fonts/SofiaPro-UltraLight.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/SofiaPro-UltraLight.woff2') format('woff2'),
      url('assets/fonts/SofiaPro-UltraLight.woff') format('woff'),
      url('assets/fonts/SofiaPro-UltraLight.ttf') format('truetype'),
      url('assets/fonts/SofiaPro-UltraLight.svg#SofiaPro-UltraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
	padding: 0;
	margin: 0;
	color: rgba(49, 49, 49, .7490196078431373);
	font-size: 18px;
	position: relative;
	min-height: 100%
}

body:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	width: 100%;
	height: 100%;
	background: transparent linear-gradient(213deg, #0176b6, #f3b77b 31%, #f3b77b 69%, #0176b6) 0 0 no-repeat padding-box;
	opacity: .1
}

ul {
	list-style: none;
	padding: 0;
	margin: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 20px;
	font-family: 'Sofia Pro SemiBold'
}

p {
	font-family: 'Jost', sans-serif;
	margin: 0 0 30px;
	letter-spacing: .61px
}

a,
a:hover {
	color: #313131;
	opacity: .8;
	text-decoration: none
}

.btn:focus {
	outline: none;
	box-shadow: none
}

.btn-md {
	font-family: 'Sofia Pro Medium';
	width: 276px;
	border-radius: 16px;
	background: transparent linear-gradient(253deg, #f3b77b, #d87615) 0 0 no-repeat padding-box;
	height: 77px;
	color: #fff;
	text-align: center;
	padding: 23px 0!important;
	overflow: hidden;
	font-size: 24px;
	letter-spacing: 1.2px;
	line-height: 30px;
	border: 1px solid #ee9740;
	transition: all .5s ease-in-out
}

.btn-md,
.btn-md span {
	position: relative
}

.btn-md:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	background: transparent linear-gradient(255deg, #4bbfff, #0176b6) 0 0 no-repeat padding-box;
	opacity: 0
}

.btn-md:before,
.btn-md:hover {
	transition: all .5s ease-in-out
}

.btn-md:hover {
	color: #fff;
	border: 1px solid #0176b6
}

.btn-md:hover:before {
	opacity: 1;
	transition: all .5s ease-in-out
}

@media (min-width:1360px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1200px
	}
}

.section-header {
	margin-bottom: 50px
}

.section-header h5 {
	font-size: 20px;
	margin-bottom: 10px;
	letter-spacing: .6px;
	color: #ee9740
}

.section-header h3 {
	font-size: 60px;
	color: #313131;
	letter-spacing: 1.8px;
	margin-bottom: 40px
}

.section-header h3 span {
	position: relative;
	padding-bottom: 10px
}

.section-header h3 span:before {
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 100%;
	height: 3px;
	content: "";
	background-color: #ee9740
}

.section-header p {
	font-size: 18px
}

.swiper-button-next,
.swiper-button-prev {
	top: auto;
	bottom: 0;
	width: 67px;
	height: 67px;
	background: transparent linear-gradient(223deg, #ffe7c6, #ffebd1) 0 0 no-repeat padding-box;
	border-radius: 100%;
	border: 1px solid #fadebf;
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
	transition: all .5s
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
	transition: all .5s;
	border: 1px solid #dbeef8;
	background: transparent linear-gradient(223deg, #ddf6ff, #e0f7ff) 0 0 no-repeat padding-box
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
	right: calc(50% - 60px);
	left: auto;
	transform: translateX(50%)
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
	left: calc(50% - 60px);
	right: auto;
	transform: translateX(-50%)
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
	content: "";
	font-family: 'FontAwesome';
	color: #ed9436;
	transition: all .3s;
	font-size: 24px
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
	content: "";
	font-family: 'FontAwesome';
	color: #ed9436;
	transition: all .3s;
	font-size: 24px
}

.swiper-button-next:hover:after,
.swiper-button-prev:hover:after {
	transition: all .3s;
	color: #4ca2cf
}

.section-pad {
	padding: 130px 0
}

.error-message {
	position: absolute;
	bottom: 12px;
	left: 15px;
	font-size: 12px;
	color: #f44336
}

@media (max-width:1200px) {
	.btn-md {
		width: 200px
	}
}

@media (max-width:992px) {
	.section-pad {
		padding: 100px 0
	}
	.modal-dialog {
		padding: 35px 50px;
		border-radius: 40px
	}
	.btn-md {
		width: 260px
	}
}

@media (max-width:767px) {
	.btn-md {
		font-size: 18px;
		line-height: 20px;
		height: 60px;
		width: 180px;
		padding: 15px 0!important
	}
	.section-header h3 {
		font-size: 30px
	}
	.section-header h5 {
		font-size: 16px
	}
	.section-pad {
		padding: 80px 0
	}
	.section-header p {
		font-size: 16px
	}
	.section-header p br {
		display: none
	}
	.swiper-button-next,
	.swiper-button-prev {
		width: 50px;
		height: 50px
	}
	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next {
		left: calc(50% - 35px)
	}
	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev {
		right: calc(50% - 35px)
	}
}

@media (max-width:480px) {
	.btn-md {
		font-size: 16px;
		line-height: 18px;
		height: 50px;
		width: 150px;
		border-radius: 10px;
		padding: 10px 0!important
	}
	.section-pad {
		padding: 40px 0
	}
	.section-header p {
		font-size: 14px
	}
	.section-header {
		margin-bottom: 30px
	}
	.swiper-button-next:after,
	.swiper-button-prev:after,
	.swiper-container-rtl .swiper-button-next:after,
	.swiper-container-rtl .swiper-button-prev:after {
		font-size: 20px
	}
	.modal-dialog {
		padding: 20px;
		border-radius: 20px
	}
	.modal-header .close {
		top: 0;
		right: 0;
		padding: 5px
	}
	.modal-title {
		font-size: 24px
	}
	.modal-header {
		margin-bottom: 30px
	}
}